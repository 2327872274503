import { COLORS } from "@/constants/theme";
import { FormInputs, FormLabelObj, ValidationMessages } from "@/types/form";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { ErrorMessage } from "@hookform/error-message";
import { FC } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Link } from "react-router-dom";

type Props = {
  name: any;
  label: string | FormLabelObj;
  register: UseFormRegister<FormInputs>;
  errors: FieldErrors<FormInputs>;
  errorMessages?: ValidationMessages;
  isRequired: boolean;
};

export const Checkbox: FC<Props> = ({
  name,
  label,
  errors,
  register,
  errorMessages,
}) => {
  const resolveCheckboxLabel = (label: string | FormLabelObj) => {
    let parts: string[];
    if (typeof label === "object") {
      parts = label.text.split(label.linkText) || [];
      console.log(parts);
      return (
        <>
          {parts[0]}
          <Link to={label.link as string} target="_blank">
            {label.linkText}
          </Link>
          {parts[1]}
        </>
      );
    } else {
      return label;
    }
  };

  return (
    <>
      <div css={classes.checkbox}>
        <input
          type="checkbox"
          {...register(name, {
            required: errorMessages?.required,
          })}
        />
        <p>{resolveCheckboxLabel(label)}</p>
      </div>
      <p css={[classes.errorText, classes.checkboxErrorText]}>
        <ErrorMessage errors={errors} name={name} />
      </p>
    </>
  );
};

const classes = {
  checkbox: css`
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      margin-right: 12px;
      cursor: pointer;
    }
    p {
      font-size: 14px;
    }
    p > a {
      color: ${COLORS.main};
      text-decoration: underline;
    }
    ${mqMax("small")} {
      justify-content: flex-start;
      width: 100%;
      input {
        margin-right: 12px;
        flex: none;
      }
      p {
        font-size: 13px;
        line-height: 1.5;
      }
    }
  `,
  errorText: css`
    font-size: 14px;
    margin-top: 8px;
    font-weight: 500;
    color: ${COLORS.main};
  `,
  textareaErrorText: css`
    margin-bottom: 80px;
  `,
  checkboxErrorText: css`
    text-align: center;
    margin-bottom: 16px;
  `,
};
