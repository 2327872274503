import { GraphQLClient } from "graphql-request";
import { useEffect, useState } from "react";

type GraphQLData = any;
type FetchError = Error | null;

/**
 * GraphQLのクエリを実行し、結果とローディング状態、発生したエラーを返します。
 *
 * @param query 実行するGraphQLのクエリです。
 * @returns 以下のプロパティを含むオブジェクトを返します：
 * - `data`: クエリの結果です。クエリが完了するまでは `null` となります。
 * - `isLoading`: クエリが現在実行中かどうかを示す真偽値です。
 * - `isError`: クエリの実行中にエラーが発生した場合のエラーオブジェクト、エラーが発生しなかった場合は `null` です。
 *
 * @example
 * const { data, isLoading, isError } = useGraphQLQuery("{ posts { edges { node { title, date, content } } } }");
 */
const useGraphQLQuery = (query: string) => {
  const [data, setData] = useState<GraphQLData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<FetchError>(null);

  useEffect(() => {
    const fetchData = async () => {
      const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT!;
      const graphQLClient = new GraphQLClient(endpoint);

      try {
        const result: GraphQLData = await graphQLClient.request(query);
        setData(result);
        setIsLoading(false);
      } catch (err) {
        setIsError(err as Error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [query]);

  return { data, isLoading, isError };
};

export default useGraphQLQuery;
