// @ts-nocheck
// FIXME: 型チェック無視の解除
import Heading from "@/components/Heading";
import { css } from "@emotion/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FONT_SIZE } from "@/constants/theme";
import { Pagination, Autoplay } from "swiper/modules";
import "@/styles/solution-swiper.css";
import { mqMax } from "@/utils/createMediaQuery";

// TODO: ソリューション内容の検討
const solutionList = [
  {
    id: 1,
    title: "システムコンサルティング",
    details: [
      "ビジネスが直面する課題を解決するため、私たちはITソリューションの設計と提案に取り組みます。",
      "このプロセスでは、顧客のビジネスが抱える特定の問題を理解し、その核心に迫ることから始めます。",
      "問題解決のための戦略的アプローチを探求し、最も適切なITツールや技術を活用して、問題を解消するソリューションを設計します。",
    ],
    imgPath: "/images/solution-01.webp",
  },
  {
    id: 2,
    title: "Webアプリ開発",
    details: [
      "私たちはお客様のビジネスニーズに対応したオーダーメイドのWebアプリケーションを開発します。",
      "これには、ユーザーエクスペリエンスを高めるデザイン、スケーラブルなアーキテクチャ、そしてセキュリティに優れたコードが含まれます。",
      "React, Angular, Vue.jsなどの最先端技術を駆使して、高品質なプロダクトをお届けします。",
    ],
    imgPath: "/images/solution-02.webp",
  },
  {
    id: 3,
    title: "インフラ構築",
    details: [
      "インフラはビジネスの成長を支える基盤です。",
      "私たちはAWS, Azure, Google Cloudなどのクラウドサービスを用いて、スケーラブルで信頼性の高いインフラ環境を設計・構築します。",
      "これにより、お客様のビジネスがスムーズに進行することを保証します。",
    ],
    imgPath: "/images/solution-03.webp",
  },
  {
    id: 4,
    title: "業務改善システム開発",
    details: [
      "日々の業務において効率化が求められる場面は多くあります。",
      "私たちの業務改善システムは、ワークフローを最適化し、手作業を自動化することで生産性を向上させます。",
      "例えば、在庫管理、顧客管理、財務分析など、多岐に渡る業務プロセスに適用可能です。",
    ],
    imgPath: "/images/solution-04.webp",
  },
  {
    id: 5,
    title: "パッケージ化ツール開発",
    details: [
      "時には市販のソフトウェアではビジネスの特定の要求に応えられない場合があります。",
      "そんな時には、私たちが開発するカスタムパッケージ化ツールが解決策となります。",
      "これらのツールは独自のニーズに対応するよう設計されており、業務効率と生産性の向上に貢献します。",
    ],
    imgPath: "/images/solution-05.webp",
  },
];

const Solution = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        '">' +
        solutionList[index].title +
        "</span>"
      );
    },
  };

  return (
    <>
      <div id="solution" css={classes.solution}>
        <Heading
          enText={"SOLUTION"}
          jpText={"ソリューション"}
          isCenter={true}
        />

        {/* TODO: Next,Prev矢印追加 */}
        <Swiper
          pagination={pagination}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
          }}
          modules={[Autoplay, Pagination]}
          css={classes.soltionSwiper}
        >
          {solutionList.map((v) => {
            return (
              <SwiperSlide key={v.id}>
                <img src={v.imgPath} alt={v.title} />
                <div css={classes.slideTextBox}>
                  <h3>{v.title}</h3>
                  {v.details.map((detail, index) => (
                    <p key={index}>{detail}</p>
                  ))}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

const classes = {
  solution: css`
    margin-top: 240px;
    ${mqMax("small")} {
      margin-top: 120px;
    }
  `,
  soltionSwiper: css`
    display: flex !important;
    flex-flow: column-reverse;
    img {
      width: 470px;
      height: 400px;
      object-fit: cover;
    }
    ${mqMax("small")} {
      img {
        width: 100%;
        height: 240px;
      }
    }
  `,
  slideTextBox: css`
    width: 400px;
    margin-left: 48px;
    h3 {
      margin-top: 32px;
      font-size: ${FONT_SIZE.h3};
      font-weight: bold;
    }
    p {
      margin-top: 24px;
      line-height: 1.8;
      &:first-of-type {
        margin-top: 40px;
      }
    }
    ${mqMax("small")} {
      width: 100%;
      margin: 0;
      h3 {
        margin-top: 24px;
        font-size: 24px;
      }
      p {
        font-size: 15px;
      }
    }
  `,
};

export default Solution;
