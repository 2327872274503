export const COLORS = {
  main: "#C52723",
  black: "#000000",
  white: "#FFFFFF",
  darkGray: "#86929E",
  gray: "#ACB3B8",
};

export const FONT_SIZE = {
  h2: "40px",
  h3: "28px",
  h4: "22px",
};
