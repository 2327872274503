import { css } from "@emotion/react";
import { mqMax } from "@/utils/createMediaQuery";

const Access = () => {
  return (
    <>
      <div css={classes.antisocialForces}>
        <h2>概要</h2>
        <p>
          当会社は、市民社会の秩序や安全に脅威を与える反社会的勢力と一切の関係を持たないことを掲げ、公共の信頼を維持し、健全な企業経営を実現するためこの基本方針を定めます。
        </p>
        <h2>基本原則</h2>
        <h3>組織としての対応</h3>
        <p>
          反社会的勢力への対応については、担当者や担当部署だけに任せずに、経営トップ以下組織全体として対応する。反社会的勢力による不当要求に対応する従業員の安全を確保する。
        </p>
        <h3>外部専門機関との連携</h3>
        <p>
          反社会的勢力による不当要求に備えて、平素から、警察、公益財団法人暴力団追放運動推進都民センター、弁護士等の外部の専門機関と緊密な連携関係を構築する。
        </p>
        <h3>取引を含めた一切の関係遮断</h3>
        <p>
          反社会的勢力とは、取引関係を含めて、一切の関係を持たない。また、反社会的勢力による不当要求は拒絶する。
        </p>
        <h3>有事における民事と刑事の法的対応</h3>
        <p>
          反社会的勢力による不当要求に対しては、民事と刑事の両面から法的対応を行う。
        </p>
        <h3>裏取引や資金提供の禁止</h3>
        <p>
          反社会的勢力による不当要求が、事業活動等の不祥事を理由とする場合であっても、事案を隠ぺいするための裏取引を絶対に行わない。反社会的勢力への資金提供は絶対に行わない。
        </p>
      </div>
    </>
  );
};

const classes = {
  antisocialForces: css`
    margin-top: 96px;
    h2 {
      margin-top: 64px;
      font-weight: bold;
      font-size: 24px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    h3 {
      font-weight: bold;
      margin-top: 64px;
      font-size: 20px;
    }
    p {
      margin-top: 24px;
      line-height: 1.8;
    }
    ${mqMax("small")} {
      margin-top: 64px;
      h2 {
        font-size: 20px;
      }
      h3 {
        font-size: 18px;
      }
      p {
        font-size: 15px;
      }
    }
  `,
};

export default Access;
