import { FormInputs } from "@/types/form";
import { init, send } from "emailjs-com";

type SendMailOptions = {
  data: FormInputs;
  isContact: boolean;
};

export const sendMail = ({ data, isContact }: SendMailOptions) => {
  const userID = process.env.REACT_APP_EMAILJS_USER_ID;
  const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;

  // TODO: 自動返信メールの実装
  // EmailJSだと2種類のテンプレートしか使えないため、暫定的に自動返信メールは実装しない
  const templateID = isContact
    ? process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID
    : process.env.REACT_APP_EMAILJS_DOWNLOAD_TEMPLATE_ID;

  if (!userID || !serviceID || !templateID) {
    // TODO: エラー処理の追加
    return;
  }

  init(userID);

  const mailParam: FormInputs = {
    name: data.name,
    kana: data.kana,
    company: data.company,
    tel: data.tel,
    email: data.email,
    content: data.content,
    information: data.information,
    privacy: data.privacy,
  };

  send(serviceID, templateID, mailParam);
};
