import { COLORS } from "@/constants/theme";
import useGraphQLQuery from "@/hooks/useGraphQLQuery";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";

const NewsDetail = () => {
  const { pageId } = useParams();

  const query = `
    {
      postBy(postId: ${pageId}) {
        postId
        title
        date
        content
      }
    }
  `;
  const { data, isLoading, isError } = useGraphQLQuery(query);

  if (isError) {
    return (
      <div>Error occurred while fetching data. Please try again later.</div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {!isLoading && data.postBy.title + " | 株式会社Growth One"}
        </title>
        <meta property="og:title" content="株式会社Growth One" />
        <meta property="og:description" content="株式会社Growth One" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div css={classes.newsDetail}>
        {!isLoading && (
          <>
            <div css={classes.titleBox}>
              <time>{data.postBy.date.split("T")[0].replace(/-/g, ".")}</time>
              <h1>{data.postBy.title}</h1>
            </div>
            <div css={classes.content}>
              <div dangerouslySetInnerHTML={{ __html: data.postBy.content }} />
            </div>
            <div css={classes.shareBox}>
              <h2>記事をシェア</h2>
              <div css={classes.shareLinks}>
                <Link
                  to={`https://twitter.com/share?url=https://growth-one.co.jp/news/${data.postBy.postId}`}
                  target="_blank"
                >
                  <img src="/images/twitter.svg" alt="Twitter" />
                </Link>
                <Link
                  to={`https://www.facebook.com/share.php?u=https://growth-one.co.jp/news/${data.postBy.postId}`}
                  target="_blank"
                >
                  <img src="/images/facebook.svg" alt="Facebook" />
                </Link>
                <Link
                  to={`https://timeline.line.me/social-plugin/share?url=https://growth-one.co.jp/news/${data.postBy.postId}`}
                  target="_blank"
                >
                  <img src="/images/line.svg" alt="LINE" />
                </Link>
              </div>
            </div>
          </>
        )}
        <Link css={classes.toListLink} to="/news">
          <span>←</span>
          記事一覧へ
        </Link>
      </div>
    </>
  );
};

const classes = {
  newsDetail: css``,
  titleBox: css`
    time {
      font-weight: 500;
      color: ${COLORS.darkGray};
    }
    h1 {
      line-height: 1.5;
      font-size: 32px;
      font-weight: bold;
      margin-top: 24px;
    }
    ${mqMax("small")} {
      h1 {
        font-size: 24px;
      }
    }
  `,
  content: css`
    margin-top: 64px;
    h2 {
      line-height: 1.5;
      position: relative;
      margin: 64px 0 48px;
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 12px;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 4px;
        background: #d9d9d9;
        bottom: -12px;
        left: 0;
      }
      &::after {
        position: absolute;
        content: "";
        width: 25%;
        height: 4px;
        background: ${COLORS.main};
        bottom: -12px;
        left: 0;
      }
    }
    h3 {
      line-height: 1.5;
      font-size: 20px;
      font-weight: bold;
      margin: 64px 0 48px;
      border-left: 4px solid ${COLORS.main};
      padding: 4px 12px;
    }
    p {
      margin-top: 24px;
      line-height: 1.8;
    }
    ${mqMax("small")} {
      h2 {
        font-size: 20px;
      }
      h3 {
        font-size: 18px;
      }
      p {
        font-size: 15px;
      }
    }
  `,
  shareBox: css`
    margin-top: 80px;
    h2 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
  `,
  shareLinks: css`
    margin-top: 32px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    a {
      text-align: center;
      transition: 0.3s;
      display: inline-block;
      width: 256px;
      border-right: 1px solid ${COLORS.gray};
      &:last-of-type {
        border-right: none;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  `,
  toListLink: css`
    position: relative;
    left: -8px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s;
    span {
      margin-right: 12px;
      text-align: center;
      line-height: 24px;
      display: inline-block;
      font-weight: bold;
      height: 24px;
      width: 24px;
      border-radius: 100px;
      color: ${COLORS.white};
      background: ${COLORS.black};
      transition: 0.3s;
    }
    &:hover {
      color: ${COLORS.main};
      span {
        background: ${COLORS.main};
        padding-right: 2px;
      }
    }
  `,
};

export default NewsDetail;
