import Heading from "@/components/Heading";
import { COLORS } from "@/constants/theme";
import useGraphQLQuery from "@/hooks/useGraphQLQuery";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const News = () => {
  const query = `
  {
    posts(first: 3, where: {orderby: {field: DATE, order: DESC}}) {
      edges {
        node {
          postId
          title
          content
          date
        }
      }
    }
  }
`;
  const { data, isLoading, isError } = useGraphQLQuery(query);

  if (isError) {
    return (
      <div>Error occurred while fetching data. Please try again later.</div>
    );
  }
  return (
    <>
      <div css={classes.news}>
        <Heading enText={"NEWS"} jpText={"お知らせ"} />
        <ul css={classes.newsList}>
          {!isLoading &&
            data.posts.edges.map((v: any) => {
              return (
                <li key={v.node.postId} css={classes.newsListItem}>
                  <time>{v.node.date.split("T")[0].replace(/-/g, ".")}</time>
                  <Link key={v.node.postId} to={`/news/${v.node.postId}`}>
                    {v.node.title}
                  </Link>
                </li>
              );
            })}
        </ul>
        <Link css={classes.toListLink} to="/news">
          記事一覧へ
          <span>→</span>
        </Link>
      </div>
    </>
  );
};

const classes = {
  news: css`
    margin-top: 144px;
  `,
  newsList: css`
    margin-top: 48px;
  `,
  newsListItem: css`
    padding: 32px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f3f6f9;
    &:first-of-type {
      padding-top: 0;
    }
    time {
      color: ${COLORS.darkGray};
      font-size: 13px;
      font-weight: 500;
    }
    a {
      display: block;
      margin-left: 96px;
      transition: 0.3s;
      &:hover {
        color: ${COLORS.main};
      }
    }
    ${mqMax("small")} {
      time {
        font-size: 12px;
      }
      a {
        margin-left: 16px;
        font-size: 13px;
        line-height: 1.5;
      }
    }
  `,
  toListLink: css`
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s;
    span {
      margin-left: 12px;
      text-align: center;
      line-height: 24px;
      display: inline-block;
      font-weight: bold;
      height: 24px;
      width: 24px;
      border-radius: 100px;
      color: ${COLORS.white};
      background: ${COLORS.black};
      transition: 0.3s;
    }
    &:hover {
      color: ${COLORS.main};
      span {
        background: ${COLORS.main};
        padding-left: 2px;
      }
    }
  `,
};

export default News;
