import { Helmet } from "react-helmet-async";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Heading from "@/components/Heading";
import Access from "./Access";
import CompanyInfo from "./CompanyInfo";
import { ChildPageLayout } from "@/components/Layout/ChildPageLayout";

export const Company = () => {
  return (
    <>
      <Helmet>
        <title>会社概要 | 株式会社Growth One</title>
        <meta property="og:title" content="株式会社Growth One" />
        <meta property="og:description" content="株式会社Growth One" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <ChildPageLayout>
        <Heading enText={"COMPANY"} jpText={"会社概要"} isCenter={true} />
        <CompanyInfo />
        <Access />
      </ChildPageLayout>
      <Footer />
    </>
  );
};
