import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { ChildPageLayout } from "@/components/Layout/ChildPageLayout";
import { FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { Form } from "@/components/Form";
import Heading from "@/components/Heading";
import { FormStep } from "@/types/form";
import { Confirm } from "@/components/Form/Confirm";
import { Thanks } from "./Thanks";
import { Helmet } from "react-helmet-async";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const DownloadForm = () => {
  const [step, setStep] = useState<FormStep>("form");

  const methods = useForm({
    mode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
  });

  const renderView = () => {
    switch (step) {
      case "form":
        return (
          <Form changeNextStep={() => setStep("confirm")} isContact={false} />
        );
      case "confirm":
        return (
          <Confirm
            changeNextStep={() => setStep("thanks")}
            changePrevStep={() => setStep("form")}
            isContact={false}
          />
        );
      case "thanks":
        return <Thanks />;
    }
  };

  return (
    <>
      <Helmet>
        <title>資料ダウンロード | 株式会社Growth One</title>
        <meta property="og:title" content="株式会社Growth One" />
        <meta property="og:description" content="株式会社Growth One" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <ChildPageLayout>
        <FormProvider {...methods}>
          <Heading
            enText={"DOWNLOAD"}
            jpText={"資料ダウンロード"}
            isCenter={true}
          />
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY!}
          >
            {renderView()}
          </GoogleReCaptchaProvider>
        </FormProvider>
      </ChildPageLayout>
      <Footer />
    </>
  );
};
