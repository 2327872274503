import { FC, useCallback } from "react";
import { css } from "@emotion/react";
import { useFormContext } from "react-hook-form";
import { COLORS } from "@/constants/theme";
import { FormInputs } from "@/types/form";
import { mqMax } from "@/utils/createMediaQuery";
import { CONTACT_FORM_ITEMS, DOWNLOAD_FORM_ITEMS } from "@/constants/form";
import { sendMail } from "@/features/email/sendMail";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";

type Props = {
  changeNextStep: () => void;
  changePrevStep: () => void;
  isContact: boolean;
};

type InputValues = {
  label: any;
  name: string;
  value: string | boolean;
  isRequired: boolean;
};

export const Confirm: FC<Props> = ({
  changeNextStep,
  changePrevStep,
  isContact,
}) => {
  const { handleSubmit, getValues } = useFormContext<FormInputs>();
  const values = getValues();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const formItems = isContact ? CONTACT_FORM_ITEMS : DOWNLOAD_FORM_ITEMS;

  const labeledValues: InputValues[] = formItems.map((v) => {
    return {
      label: v.label,
      name: v.name,
      isRequired: v.isRequired,
      value: values[v.name] || "",
    };
  });

  const createStyles = (name: string) => {
    let styles = [classes.confirmText];
    if (name === "content") {
      styles.push(classes.contentConfirmText);
    }
    if (name === "privacy") {
      styles.push(classes.checkboxConfirmText);
    }
    return styles;
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const recaptchaToken = await executeRecaptcha("form_submit");

    await axios
      .post(process.env.REACT_APP_RECAPTCHA_VERIFY_URL!, {
        response: recaptchaToken,
      })
      .then((res) => {
        const isSuccess = res.data.success;
        if (isSuccess) {
          sendMail({ data: values, isContact: isContact });
          changeNextStep();
        } else {
          console.log("error");
          alert("送信に失敗しました");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [changeNextStep, executeRecaptcha, isContact, values]);

  const onSubmitHandler = async () => {
    await handleReCaptchaVerify();
  };

  return (
    <form css={classes.form} onSubmit={handleSubmit(onSubmitHandler)}>
      {labeledValues.map((v) => {
        return (
          <div css={classes.formItem} key={v.name}>
            <h3>
              {(typeof v.label === "object" && v.label.text) || v.label}
              {v.isRequired && <span>必須</span>}
            </h3>
            <p css={createStyles(v.name)}>
              {typeof v.value === "string" ? v.value : "同意する"}
            </p>
          </div>
        );
      })}

      <div css={classes.submitBtnWrap}>
        <button type="submit" css={classes.submitBtn}>
          送信する
        </button>
      </div>
      <div css={classes.submitBtnWrap}>
        <button
          onClick={changePrevStep}
          css={[classes.submitBtn, classes.graySubmitBtn]}
        >
          修正する
        </button>
      </div>
    </form>
  );
};

const classes = {
  form: css`
    margin-top: 48px;
  `,
  formItem: css`
    margin-top: 32px;
    &:first-of-type {
      margin-top: 0;
    }
    h3 {
      display: flex;
      align-items: center;
      font-weight: bold;
    }
    span {
      font-size: 12px;
      font-weight: 500;
      color: ${COLORS.white};
      display: block;
      padding: 4px 8px;
      background: ${COLORS.main};
      border-radius: 4px;
      margin-left: 4px;
    }
    ${mqMax("small")} {
      h3 {
        line-height: 1.5;
      }
      span {
        flex: none;
        padding: 2px 8px;
        margin-left: 8px;
        font-size: 11px;
      }
    }
  `,
  submitBtnWrap: css`
    text-align: center;
    &:last-of-type {
      margin-top: 32px;
    }
  `,
  submitBtn: css`
    cursor: pointer;
    appearance: none;
    background: ${COLORS.main};
    color: ${COLORS.white};
    padding: 16px 120px;
    border-radius: 200px;
    font-weight: bold;
    border: none;
    transition: 0.3s;
    margin: 0 auto;
    &:hover {
      opacity: 0.6;
    }
    ${mqMax("small")} {
      display: inline-block;
      padding: 16px 0;
      width: 100%;
    }
  `,
  graySubmitBtn: css`
    background: ${COLORS.gray};
  `,
  confirmText: css`
    margin-top: 16px;
  `,
  contentConfirmText: css`
    white-space: pre-wrap;
  `,
  checkboxConfirmText: css`
    margin-bottom: 80px;
  `,
};
