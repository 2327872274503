import { useRoutes } from "react-router-dom";
import { Home } from "@/pages/home";
import { Company } from "@/pages/company";
import { AntisocialForces } from "@/pages/antisocial-forces";
import { News } from "@/pages/news";
import NewsDetail from "@/pages/news/NewsDetail";
import { ContactForm } from "@/pages/contact-form";
import { DownloadForm } from "@/pages/download-form";

const routes = [
  { path: "/", element: <Home /> },
  { path: "/company", element: <Company /> },
  { path: "/antisocial-forces", element: <AntisocialForces /> },
  {
    path: "/news",
    element: <News />,
    children: [{ path: ":pageId", element: <NewsDetail /> }],
  },
  {
    path: "/contact",
    element: <ContactForm />,
  },
  {
    path: "/download",
    element: <DownloadForm />,
  },
];

const AppRoutes = () => {
  const element = useRoutes([...routes]);
  return <>{element}</>;
};
export default AppRoutes;
