import AppRoutes from "@/routes/index";
import emotionReset from "emotion-reset";
import { Global, css } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import ScrollTop from "./features/ScrollTop";
import { HelmetProvider } from "react-helmet-async";
import ProgressBar from "./features/ProgressBar";

const App = () => {
  return (
    <>
      <HelmetProvider>
        <Global styles={styles} />
        <BrowserRouter>
          <ScrollTop />
          <ProgressBar />
          <AppRoutes />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
};

const styles = css`
  ${emotionReset}
  @font-face {
    font-family: "NotoSansJP";
    src: url("/fonts/NotoSansJP-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "NotoSansJP";
    src: url("/fonts/NotoSansJP-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "NotoSansJP";
    src: url("/fonts/NotoSansJP-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  body {
    letter-spacing: 0.04em;
    font-family: "NotoSansJP";
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;

export default App;
