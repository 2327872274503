import { COLORS } from "@/constants/theme";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { FC } from "react";

type Props = {
  onClickHandler: () => void;
  isActive: boolean;
};

export const HamburgerButton: FC<Props> = ({ onClickHandler, isActive }) => {
  return (
    <>
      <button
        css={
          isActive
            ? [classes.hamburgerBtn, classes.active]
            : classes.hamburgerBtn
        }
        onClick={onClickHandler}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </>
  );
};

const classes = {
  hamburgerBtn: css`
    display: none;
    ${mqMax("small")} {
      display: block;
    }
    appearance: none;
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    background: ${COLORS.main};
    position: relative;
    transition: 0.3s;
    span {
      position: absolute;
      display: inline-block;
      height: 2px;
      width: 20px;
      transition: 0.4s;
      background: ${COLORS.white};
      left: 50%;
      transform: translateX(-50%);
      border-radius: 4px;
    }
    span:nth-of-type(1) {
      top: 28%;
    }
    span:nth-of-type(2) {
      top: 48%;
    }
    span:nth-of-type(3) {
      top: 68%;
    }
  `,
  active: css`
    background: ${COLORS.white};
    span {
      background: ${COLORS.main};
    }
    span:nth-of-type(1) {
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
    }
    span:nth-of-type(2) {
      display: none;
    }
    span:nth-of-type(3) {
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    }
  `,
};
