import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { ChildPageLayout } from "@/components/Layout/ChildPageLayout";
import Heading from "@/components/Heading";
import Content from "./Content";
import { Helmet } from "react-helmet-async";

export const AntisocialForces = () => {
  return (
    <>
      <Helmet>
        <title>反社会的勢力への対応に関する基本方針 | 株式会社Growth One</title>
        <meta property="og:title" content="株式会社Growth One" />
        <meta property="og:description" content="株式会社Growth One" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Header />
      <ChildPageLayout>
        <Heading
          enText={"ANTISOCIAL FORCES"}
          jpText={"反社会的勢力への対応に関する基本方針"}
          isCenter={true}
        />
        <Content />
      </ChildPageLayout>
      <Footer />
    </>
  );
};
