import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { css } from "@emotion/react";
import { COLORS } from "@/constants/theme";
import FormItem from "@/components/Form/FormItem";
import { CONTACT_FORM_ITEMS, DOWNLOAD_FORM_ITEMS } from "@/constants/form";
import { FormInputs } from "@/types/form";
import { mqMax } from "@/utils/createMediaQuery";

type Props = {
  changeNextStep: () => void;
  isContact: boolean;
};

export const Form: FC<Props> = ({ changeNextStep, isContact }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<FormInputs>();

  const formItems = isContact ? CONTACT_FORM_ITEMS : DOWNLOAD_FORM_ITEMS;

  return (
    <form css={classes.form} onSubmit={handleSubmit(changeNextStep)}>
      {formItems.map((v) => {
        return (
          <FormItem
            key={v.id}
            name={v.name}
            label={v.label}
            register={register}
            errors={errors}
            inputType={v.inputType}
            errorMessages={v.errorMessages}
            isRequired={v.isRequired}
            isContact={isContact}
          />
        );
      })}

      <div css={classes.submitBtnWrap}>
        <button type="submit" css={classes.submitBtn}>
          入力内容を確認する
        </button>
      </div>
    </form>
  );
};

const classes = {
  form: css`
    margin-top: 48px;
  `,
  submitBtnWrap: css`
    text-align: center;
    &:last-of-type {
      margin-top: 32px;
    }
  `,
  submitBtn: css`
    cursor: pointer;
    appearance: none;
    background: ${COLORS.main};
    color: ${COLORS.white};
    padding: 16px 120px;
    border-radius: 200px;
    font-weight: bold;
    border: none;
    transition: 0.3s;
    margin: 0 auto;
    &:hover {
      opacity: 0.6;
    }
    ${mqMax("small")} {
      width: 100%;
      padding: 16px 0;
    }
  `,
};
