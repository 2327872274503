import { COLORS } from "@/constants/theme";
import { NavListItem } from "@/types/list";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";

/**
 * ヘッダーのナビゲーションリストのスタイルを作成する関数。
 * @param listItem ヘッダーのメニュー項目
 */
const createListStyle = (listItem: NavListItem) => {
  let styles = [classes.headerNavItem];
  if (listItem.name === "資料請求") {
    styles.push(classes.headerCvBtn);
  } else if (listItem.name === "お問い合わせ") {
    styles.push(classes.headerCvBtn, classes.headerCvBtnRed);
  }
  return styles;
};

export default createListStyle;

const classes = {
  headerNavItem: css`
    cursor: pointer;
    margin-right: 24px;
    a {
      font-size: 12px;
      font-weight: bold;
      transition: 0.3s;
      &:hover {
        color: ${COLORS.main};
      }
    }
    ${mqMax("small")} {
      a {
        display: inline-block;
        font-size: 18px;
        color: ${COLORS.white};
        font-weight: 500;
      }
      > a {
        padding: 20px 0;
      }
    }
  `,
  headerCvBtn: css`
    margin-right: 12px;
    a {
      display: block;
      padding: 12px 24px;
      background: ${COLORS.black};
      border-radius: 200px;
      color: ${COLORS.white};
      transition: 0.3s;
      &:hover {
        opacity: 0.6;
        color: ${COLORS.white};
      }
    }
    ${mqMax("small")} {
      width: 100%;
      margin: 80px 0 0 0;
      a {
        padding: 16px 24px;
        text-align: center;
        border: 1px solid ${COLORS.white};
        background: none;
      }
    }
  `,
  headerCvBtnRed: css`
    a {
      background: ${COLORS.main};
    }
    ${mqMax("small")} {
      width: 100%;
      margin: 24px 0 0 0;
      a {
        border: 1px solid ${COLORS.white};
      }
    }
  `,
};
