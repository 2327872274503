import { FC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "@/constants/theme";
import { Link } from "react-router-dom";
import { mqMax } from "@/utils/createMediaQuery";

export const Thanks: FC = () => {
  return (
    <>
      <div css={classes.contact}>
        <p css={classes.thanksText}>
          この度はお問い合わせいただきまして、
          <br css={classes.spShow} />
          誠にありがとうございます。
          <br />
          ご入力いただいたお問い合わせ内容は、
          <br css={classes.spShow} />
          正常に送信されました。
          <br />
          弊社から折り返しご連絡を差し上げますので、
          <br css={classes.spShow} />
          今しばらくお待ちください。
        </p>
        <div css={classes.submitBtnWrap}>
          <Link to={"/"} css={classes.submitBtn}>
            トップへ戻る
          </Link>
        </div>
      </div>
    </>
  );
};

const classes = {
  spShow: css`
    display: none;
    ${mqMax("small")} {
      display: inline;
    }
  `,
  contact: css`
    margin-top: 96px;
    > p {
      text-align: center;
      line-height: 1.8;
    }
    ${mqMax("small")} {
      margin-top: 64px;
      > p {
        font-size: 14px;
      }
    }
  `,
  form: css`
    margin-top: 48px;
  `,
  submitBtnWrap: css`
    margin-top: 80px;
    text-align: center;
  `,
  submitBtn: css`
    cursor: pointer;
    appearance: none;
    background: ${COLORS.main};
    color: ${COLORS.white};
    padding: 16px 120px;
    border-radius: 200px;
    font-weight: bold;
    border: none;
    transition: 0.3s;
    margin: 0 auto;
    &:hover {
      opacity: 0.6;
    }
    ${mqMax("small")} {
      display: inline-block;
      padding: 16px 0;
      width: 100%;
    }
  `,
  thanksText: css`
    text-align: center;
  `,
};
