const BREAK_POINTS = {
  small: 520,
  large: 1920,
};

type Breakpoints = "small" | "large";

export const mqMax = (key: Breakpoints) =>
  `@media (max-width: ${BREAK_POINTS[key]}px)`;

export const mqMin = (key: Breakpoints) =>
  `@media (min-width: ${BREAK_POINTS[key]}px)`;
