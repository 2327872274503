import { COLORS } from "@/constants/theme";
import { FormInputs, FormLabelObj, ValidationMessages } from "@/types/form";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { ErrorMessage } from "@hookform/error-message";
import { FC } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import { Checkbox } from "./Checkbox";

type Props = {
  name: any;
  label: string | FormLabelObj;
  register: UseFormRegister<FormInputs>;
  errors: FieldErrors<FormInputs>;
  errorMessages?: ValidationMessages;
  inputType: string;
  isRequired: boolean;
  isContact: boolean;
};

const FormItem: FC<Props> = ({
  label,
  name,
  inputType,
  register,
  errors,
  errorMessages,
  isRequired,
}) => {
  const renderView = () => {
    switch (inputType) {
      case "text":
        return (
          <div css={classes.formItem}>
            <h3>
              {typeof label === "string" ? label : label.text}
              {isRequired && <span>必須</span>}
            </h3>

            <input
              css={[classes.input, classes.inputM]}
              {...register(name, {
                required: errorMessages?.required,
              })}
            />
            <p css={classes.errorText}>
              <ErrorMessage errors={errors} name={name} />
            </p>
          </div>
        );
      case "textarea":
        return (
          <div css={classes.formItem}>
            <h3>
              {typeof label === "string" ? label : label.text}
              {isRequired && <span>必須</span>}
            </h3>
            <textarea
              css={[classes.input, classes.textarea]}
              rows={6}
              {...register(name, {
                required: errorMessages?.required,
              })}
            />
            <p css={[classes.errorText, classes.textareaErrorText]}>
              <ErrorMessage errors={errors} name={name} />
            </p>
          </div>
        );
      case "checkbox":
        return (
          <Checkbox
            name={name}
            label={label}
            register={register}
            errors={errors}
            isRequired={isRequired}
          />
        );
    }
  };

  return <>{renderView()}</>;
};

const classes = {
  formItem: css`
    margin-top: 32px;
    &:first-of-type {
      margin-top: 0;
    }
    h3 {
      display: flex;
      align-items: center;
      font-weight: bold;
    }
    span {
      font-size: 12px;
      font-weight: 500;
      color: ${COLORS.white};
      display: block;
      padding: 4px 8px;
      background: ${COLORS.main};
      border-radius: 4px;
      margin-left: 4px;
    }
    ${mqMax("small")} {
      h3 {
        line-height: 1.5;
      }
      span {
        flex: none;
        padding: 2px 8px;
        margin-left: 8px;
        font-size: 11px;
      }
    }
  `,
  input: css`
    display: block;
    appearance: none;
    border: 1px solid ${COLORS.gray};
    padding: 8px 16px;
    margin-top: 16px;
    border-radius: 10px;
    font-weight: bold;
  `,
  inputM: css`
    width: 50%;
    height: 50px;
    ${mqMax("small")} {
      width: 100%;
    }
  `,
  textarea: css`
    width: 100%;
    resize: none;
  `,
  errorText: css`
    font-size: 14px;
    margin-top: 8px;
    font-weight: 500;
    color: ${COLORS.main};
  `,
  textareaErrorText: css`
    margin-bottom: 80px;
  `,
};

export default FormItem;
