import { COLORS, FONT_SIZE } from "@/constants/theme";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const CTA = () => {
  return (
    <>
      <div css={classes.cta}>
        <h2>各種お問い合わせ</h2>
        <p>
          お困りのことがございましたら、
          <br css={classes.spShow} />
          どうぞお気軽にお問い合わせください。
          <br />
          GrowthOneについての
          <br css={classes.spShow} />
          資料請求も承っております。
        </p>
        <div css={classes.ctaLink}>
          <Link to="/download">
            <img src="/images/document.svg" alt="会社資料" />
            会社資料をダウンロード
          </Link>
          <Link to="/contact">
            <img src="/images/contact.svg" alt="お問い合わせ" />
            お問い合わせ・ご相談
          </Link>
        </div>
      </div>
    </>
  );
};

const classes = {
  spShow: css`
    display: none;
    ${mqMax("small")} {
      display: inline-block;
    }
  `,
  cta: css`
    margin-top: 120px;
    text-align: center;
    background: url("/images/cta.webp");
    background-size: cover;
    background-position: center;
    padding: 40px 0 48px;
    h2 {
      font-size: ${FONT_SIZE.h3};
      color: ${COLORS.white};
      font-weight: bold;
    }
    p {
      font-weight: 500;
      line-height: 1.8;
      color: ${COLORS.white};
      margin-top: 32px;
    }
    ${mqMax("small")} {
      padding: 40px 16px 48px;
      h2 {
        font-size: 24px;
      }
      p {
        font-size: 15px;
      }
    }
  `,
  ctaLink: css`
    display: flex;
    justify-content: center;
    a {
      display: flex;
      align-items: center;
      padding: 16px 40px;
      margin-top: 40px;
      border: 1px solid ${COLORS.white};
      font-weight: bold;
      color: ${COLORS.white};
      border-radius: 200px;
      transition: 0.3s;
      &:last-of-type {
        color: ${COLORS.main};
        background: ${COLORS.white};
        margin-left: 56px;
      }
      &:hover {
        opacity: 0.6;
      }
      img {
        margin-right: 12px;
      }
    }
    ${mqMax("small")} {
      flex-flow: column;
      a {
        justify-content: center;
        width: 100%;
        &:last-of-type {
          margin: 24px 0 0 0;
        }
        &:hover {
          opacity: 0.6;
        }
        img {
          margin-right: 12px;
        }
      }
    }
  `,
};

export default CTA;
