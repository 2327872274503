import { css } from "@emotion/react";
import { mqMax } from "@/utils/createMediaQuery";
import { COLORS, FONT_SIZE } from "@/constants/theme";

const Access = () => {
  return (
    <>
      <div css={classes.access}>
        <h3>
          <span></span>アクセス
        </h3>
        <p>
          〒150-0002
          <br />
          東京都渋谷区渋谷2-22-3 渋谷東口ビル6F
        </p>
        <iframe
          css={classes.map}
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1729.1499546659802!2d139.7032063380093!3d35.65888358014269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b59011e2cb3%3A0x4aa0e018a2688013!2z44CSMTUwLTAwMDIg5p2x5Lqs6YO95riL6LC35Yy65riL6LC377yS5LiB55uu77yS77yS4oiS77yTIOa4i-iwt-adseWPo-ODk-ODqyA2Zg!5e0!3m2!1sja!2sjp!4v1691966474157!5m2!1sja!2sjp"
          loading="lazy"
        ></iframe>
      </div>
    </>
  );
};

const classes = {
  access: css`
    margin-top: 96px;
    h3 {
      display: flex;
      align-items: center;
      font-size: ${FONT_SIZE.h4};
      font-weight: bold;
      span {
        margin-right: 12px;
        margin-top: 4px;
        width: 16px;
        height: 10px;
        border-radius: 100px;
        background: ${COLORS.main};
        display: inline-block;
      }
    }
    p {
      font-weight: 500;
      line-height: 1.8;
      margin-top: 48px;
    }
    ${mqMax("small")} {
      margin-top: 64px;
      h3 {
        font-size: 20px;
      }
      p {
        font-size: 15px;
        margin-top: 32px;
      }
    }
  `,
  map: css`
    width: 100%;
    height: 400px;
    border-radius: 20px;
    margin-top: 24px;
  `,
};

export default Access;
