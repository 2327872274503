import { HEADER_NAV_LIST } from "@/constants/list";
import createListStyle from "@/features/header/createListStyle";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import { useLocation } from "react-router-dom";

type Props = {
  isActive: boolean;
  onClickHandler: () => void;
};

const HamburgerMenu: FC<Props> = ({ isActive, onClickHandler }) => {
  const { pathname } = useLocation();

  return (
    <>
      {isActive && (
        <div css={classes.hamburgerMenu}>
          <ul>
            {HEADER_NAV_LIST.map((v) => {
              return (
                <li key={v.id} css={createListStyle(v)}>
                  {v.isAnker && pathname === "/" ? (
                    <Scroll
                      to={v.link}
                      smooth={true}
                      offset={-160}
                      onClick={onClickHandler}
                    >
                      {v.name}
                    </Scroll>
                  ) : v.isAnker ? (
                    <Link to="/">{v.name}</Link>
                  ) : (
                    <Link to={v.link}>{v.name}</Link>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

const classes = {
  hamburgerMenu: css`
    transition: 0.3s;
    ${mqMax("small")} {
      display: block;
      padding: 160px 16px 0;
      width: 100vw;
      height: 100vh;
      background: #1d1d1f;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 50;
    }
  `,
};

export default HamburgerMenu;
