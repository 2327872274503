import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { ChildPageLayout } from "@/components/Layout/ChildPageLayout";
import { Outlet, useParams } from "react-router-dom";
import NewsList from "./NewsList";

export const News = () => {
  const { pageId } = useParams();
  return (
    <>
      <Header />
      <ChildPageLayout>
        {pageId === undefined ? <NewsList /> : <Outlet />}
      </ChildPageLayout>
      <Footer />
    </>
  );
};
