import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const ChildPageLayout: FC<Props> = ({ children }) => {
  return (
    <div css={classes.back}>
      <div css={classes.fv}></div>
      <div css={classes.container}>{children}</div>
    </div>
  );
};

const classes = {
  fv: css`
    width: 100%;
    height: 400px;
    background: url("/images/child-page-fv.webp");
    background-position: center;
    background-size: cover;
    z-index: -10;
  `,
  back: css`
    background: #fafafa;
  `,
  container: css`
    position: relative;
    top: -176px;
    z-index: 10;
    height: auto;
    width: 960px;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    padding: 64px 80px;
    background: #fff;
    margin-bottom: -56px;
    ${mqMax("small")} {
      width: 95%;
      padding: 64px 16px;
    }
  `,
};
