import { COLORS } from "@/constants/theme";
import { css } from "@emotion/react";
import { MainLayout } from "./Layout/MainLayout";
import { Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import { mqMax } from "@/utils/createMediaQuery";
import { useLocation } from "react-router-dom";

const Header = () => {
  const { pathname } = useLocation();

  return (
    <>
      <div css={classes.toTopScrollLink}>
        <Scroll to={"root"} smooth={true}>
          <div>
            <span>↑</span>
            <span>TOP</span>
          </div>
        </Scroll>
      </div>
      <footer css={classes.footer}>
        <MainLayout>
          <img src="/images/logo-white.svg" alt="Growth One" />
          <div css={classes.footerListWrap}>
            <div css={classes.footerList}>
              <Link to="/">ホーム</Link>
              {pathname === "/" ? (
                <Scroll to="about" smooth={true} offset={-160}>
                  Growth Oneについて
                </Scroll>
              ) : (
                <Scroll to="/" smooth={true} offset={-160}>
                  Growth Oneについて
                </Scroll>
              )}
              {pathname === "/" ? (
                <Scroll to="solution" smooth={true} offset={-160}>
                  ソリューション
                </Scroll>
              ) : (
                <Scroll to="/" smooth={true} offset={-160}>
                  ソリューション
                </Scroll>
              )}
              <Link to="/news">お知らせ</Link>
              <Link to="/company">会社概要</Link>
              <Link to="/contact">お問い合わせ</Link>
              <Link to="/download">資料請求</Link>
            </div>
            <div css={classes.footerList}>
              <Link to="https://twostone-s.com/privacy/" target="_blank">
                個人情報保護方針
              </Link>
              <Link to="/antisocial-forces">反社会的勢力に対する基本方針</Link>
            </div>
            <div css={classes.footerList}>
              <p>
                <span></span>グループ関連事業・会社
              </p>
              <Link to="https://twostone-s.com/" target="_blank">
                TWOSTONE&Sons
              </Link>
              <Link to="https://b-engineer.co.jp/" target="_blank">
                Branding Engineer
              </Link>
              <Link to="https://lp.mid-works.com/" target="_blank">
                Midworks
              </Link>
              <Link to="https://tech-boost.jp/" target="_blank">
                tech boost
              </Link>
              <Link to="https://darrow-p.com/" target="_blank">
                Digital Arrow Partners
              </Link>
            </div>
          </div>
        </MainLayout>
      </footer>
    </>
  );
};

const classes = {
  toTopScrollLink: css`
    text-align: center;
    background: #1d1d1f;
    width: 100%;
    cursor: pointer;
    height: 64px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      height: 100%;
    }
    span {
      font-weight: 500;
      display: block;
      color: ${COLORS.white};
      font-size: 14px;
      margin-bottom: 4px;
    }
  `,
  footer: css`
    position: relative;
    padding: 48px 0 96px;
    background: url("./images/footer.webp");
    background-position: center;
    background-size: cover;
    ${mqMax("small")} {
      background: url("./images/footer-sp.webp");
      background-position: bottom;
      background-size: cover;
    }
  `,
  footerListWrap: css`
    margin-top: 40px;
    display: flex;
    ${mqMax("small")} {
      margin: 0;
      flex-flow: column;
    }
  `,
  footerList: css`
    margin-right: 48px;
    &:nth-of-type(2) {
      margin-right: 96px;
    }
    p {
      display: flex;
      align-items: center;
      color: ${COLORS.white};
      font-weight: bold;
      margin-bottom: 16px;
      span {
        margin: 2px 8px 0 0;
        display: inline-block;
        width: 10px;
        height: 6px;
        background: ${COLORS.white};
        border-radius: 100px;
      }
    }
    a {
      font-size: 14px;
      font-weight: 500;
      color: ${COLORS.white};
      display: block;
      margin-top: 16px;
      transition: 0.3s;
      &:first-of-type {
        margin-top: 0;
      }
      &:hover {
        opacity: 0.6;
      }
    }
    ${mqMax("small")} {
      margin: 64px 0 0 0;
      &:nth-of-type(2) {
        margin: 24px 0 0 0;
      }
      p {
        margin-bottom: 24px;
      }
      a {
        margin-top: 24px;
      }
    }
  `,
};

export default Header;
