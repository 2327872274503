import { COLORS, FONT_SIZE } from "@/constants/theme";
import { mqMax, mqMin } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";

const solutionDomain = () => {
  return (
    <>
      <div css={classes.devDomain}>
        <div css={classes.textBox}>
          <h3>多種多様な開発領域に対応</h3>
          <p>
            昨今のテクノロジー駆動型のビジネス環境では、
            <br />
            <span>多様な開発能力を持つパートナーの存在</span>が
            <br />
            一段と重要となっています。
          </p>
          <p>
            それは、あらゆるビジネスニーズに対応できるだけでなく、
            <br />
            <span>異なる分野の専門知識を活用</span>して、
            <br />
            <span>革新的で高品質なソリューション</span>を
            <br />
            提供できることを意味します。
          </p>
          <p>
            私たちGrowth Oneは、開発領域問わず
            <br />
            お客様のビジネスに最適な
            <br />
            最適なシステムソリューションを提供します。
          </p>
        </div>
        {/* TODO: 高画質画像差し替え */}
        <img src="/images/solution-domain.webp" alt="ABOUT" />
      </div>
    </>
  );
};

const classes = {
  devDomain: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 160px;
    width: 100%;
    height: 570px;
    background: linear-gradient(
      to left,
      rgba(243, 246, 249, 0),
      rgba(243, 246, 249, 1)
    );
    img {
      height: 640px;
    }
    ${mqMax("small")} {
      margin-top: 120px;
      padding: 0 16px;
      height: auto;
      flex-flow: column-reverse;
      background: linear-gradient(
        to bottom,
        rgba(243, 246, 249, 0),
        rgba(243, 246, 249, 1),
        rgba(243, 246, 249, 0)
      );
      img {
        width: 100%;
        height: auto;
      }
    }
    ${mqMin("large")} {
      img {
        margin-right: 12%;
        width: 38%;
        height: auto;
      }
    }
  `,
  textBox: css`
    margin-right: 80px;
    h3 {
      font-size: ${FONT_SIZE.h3};
      font-weight: bold;
    }
    p {
      margin-top: 32px;
      line-height: 1.8;
      font-size: 16px;
      &:first-of-type {
        margin-top: 40px;
      }
    }
    p > span {
      color: ${COLORS.main};
      font-weight: bold;
    }
    ${mqMax("small")} {
      margin: 40px 0 0 0;
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 15px;
      }
    }
  `,
};

export default solutionDomain;
