import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

export const MainLayout: FC<Props> = ({ children }) => {
  return <div css={container}>{children}</div>;
};

const container = css`
  height: 100%;
  width: 1080px;
  margin: 0 auto;
  ${mqMax("small")} {
    width: 100%;
    padding: 0 16px;
  }
`;
