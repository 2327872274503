import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { FC } from "react";

type Props = {
  enText: string;
  jpText: string;
  isCenter?: boolean;
};

const Heading: FC<Props> = ({ enText, jpText, isCenter = false }) => {
  return (
    <>
      <h2 css={classes.heading} style={isCenter ? { textAlign: "center" } : {}}>
        <span>
          <span>{enText}</span>
        </span>
        {jpText}
      </h2>
    </>
  );
};

const classes = {
  heading: css`
    font-size: 40px;
    font-weight: bold;
    white-space: pre-wrap;
    line-height: 1.6;
    > span {
      display: block;
      line-height: 0;
      margin-bottom: 8px;
    }
    > span > span {
      font-size: 16px;
      background-image: linear-gradient(45deg, #d33430, #e72a26, #b01d1a);
      color: transparent;
      display: inline-block;
      padding-left: 4px;
      background-clip: text;
      -webkit-background-clip: text;
      line-height: initial;
    }
    ${mqMax("small")} {
      font-size: 32px;
    }
  `,
};

export default Heading;
