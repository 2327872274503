import { COLORS } from "@/constants/theme";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { HamburgerButton } from "./HamburgerButton";
import { useState } from "react";
import { mqMax } from "@/utils/createMediaQuery";
import HeaderNav from "./HeaderNav";
import HamburgerMenu from "./HamburgerMenu";

const Header = () => {
  // ハンバーガーメニューの状態管理
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <header css={classes.header}>
        <Link to="/">
          {isActive ? (
            <img src="/images/logo-white.svg" alt="Growth One" />
          ) : (
            <img src="/images/logo.svg" alt="Growth One" />
          )}
        </Link>
        <HeaderNav />
        <HamburgerButton
          onClickHandler={() => {
            setIsActive(!isActive);
          }}
          isActive={isActive}
        />
      </header>
      {isActive && (
        <HamburgerMenu
          isActive={isActive}
          onClickHandler={() => {
            setIsActive(!isActive);
          }}
        />
      )}
    </>
  );
};

const classes = {
  header: css`
    z-index: 100;
    background: ${COLORS.white};
    width: 1080px;
    height: 72px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    position: fixed;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 24px 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mqMax("small")} {
      background: none;
      box-shadow: none;
      width: 100%;
      padding: 0 16px;
      img {
        height: 20px;
        width: auto;
      }
    }
  `,
};

export default Header;
