import Header from "@/components/Header";
import { MainLayout } from "@/components/Layout/MainLayout";
import MainVisual from "./MainVisual";
import PickupNews from "./PickupNews";
import About from "./About";
import Solution from "./Solution";
import SolutionDomain from "./SolutionDomain";
import News from "./News";
import CTA from "@/components/CTA";
import Footer from "@/components/Footer";
import { Helmet } from "react-helmet-async";

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>株式会社Growth One</title>
        <meta property="og:title" content="株式会社Growth One" />
        <meta property="og:description" content="株式会社Growth One" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {/* TODO: MainLayoutをコンポーネント内にする */}
      <Header />
      <MainVisual />
      <MainLayout>
        <PickupNews />
      </MainLayout>
      <About />
      <MainLayout>
        <Solution />
      </MainLayout>
      <SolutionDomain />
      <MainLayout>
        <News />
      </MainLayout>
      <CTA />
      <Footer />
    </>
  );
};
