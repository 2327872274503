import { HEADER_NAV_LIST } from "@/constants/list";
import createListStyle from "@/features/header/createListStyle";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import { Link as Scroll } from "react-scroll";
import { useLocation } from "react-router-dom";

const HeaderNav = () => {
  const { pathname } = useLocation();

  return (
    <ul css={classes.headerNav}>
      {HEADER_NAV_LIST.map((v) => {
        return (
          <li key={v.id} css={createListStyle(v)}>
            {v.isAnker && pathname === "/" ? (
              <Scroll to={v.link} smooth={true} offset={-160}>
                {v.name}
              </Scroll>
            ) : v.isAnker ? (
              <Link to="/">{v.name}</Link>
            ) : (
              <Link to={v.link}>{v.name}</Link>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const classes = {
  headerNav: css`
    display: flex;
    align-items: center;
    ${mqMax("small")} {
      display: none;
    }
  `,
};

export default HeaderNav;
