import { css } from "@emotion/react";
import { mqMax } from "@/utils/createMediaQuery";
import { COLORS, FONT_SIZE } from "@/constants/theme";

const CompanyInfo = () => {
  return (
    <>
      <div css={classes.companyInfo}>
        <h3>
          <span></span>会社概要
        </h3>
        <ul css={classes.companyInfoList}>
          <li css={classes.companyInfoListItem}>
            <h4>会社名</h4>
            <p>株式会社Growth One</p>
          </li>
          <li css={classes.companyInfoListItem}>
            <h4>所在地</h4>
            <p>
              〒150-0002
              <br />
              東京都渋谷区渋谷2-22-3
              <br css={classes.spShow} />
              渋谷東口ビル6F
            </p>
          </li>
          <li css={classes.companyInfoListItem}>
            <h4>代表者</h4>
            <p>代表取締役CEO 武石尚大</p>
          </li>
          <li css={classes.companyInfoListItem}>
            <h4>設立</h4>
            <p>2023年9月1日</p>
          </li>
        </ul>
      </div>
    </>
  );
};

const classes = {
  spShow: css`
    display: none;
    ${mqMax("small")} {
      display: inline;
    }
  `,
  companyInfo: css`
    margin-top: 104px;
    h3 {
      display: flex;
      align-items: center;
      font-size: ${FONT_SIZE.h4};
      font-weight: bold;
      span {
        margin-right: 12px;
        margin-top: 4px;
        width: 16px;
        height: 10px;
        border-radius: 100px;
        background: ${COLORS.main};
        display: inline-block;
      }
    }
    ${mqMax("small")} {
      margin-top: 64px;
      h3 {
        font-size: 20px;
      }
    }
  `,
  companyInfoList: css`
    margin-top: 72px;
    ${mqMax("small")} {
      margin-top: 64px;
    }
  `,
  companyInfoListItem: css`
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #f3f6f9;
    padding: 32px 0 32px 24px;
    &:first-of-type {
      padding-top: 0;
    }
    h4 {
      font-weight: bold;
      margin-right: 112px;
      width: 64px;
      line-height: 1.8;
    }
    p {
      line-height: 1.8;
    }
    ${mqMax("small")} {
      padding: 32px 0;
      h4 {
        font-weight: bold;
        margin-right: 0;
        width: 96px;
        font-size: 15px;
      }
      p {
        font-size: 15px;
      }
    }
  `,
};

export default CompanyInfo;
