import { FormItem } from "@/types/form";

export const CONTACT_FORM_ITEMS: FormItem[] = [
  {
    id: 1,
    label: "お名前",
    name: "name",
    inputType: "text",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
  {
    id: 2,
    label: "お名前(カナ)",
    name: "kana",
    inputType: "text",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
  {
    id: 3,
    label: "会社名",
    name: "company",
    inputType: "text",
    isRequired: false,
  },
  {
    id: 5,
    label: "電話番号",
    name: "tel",
    inputType: "text",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
  {
    id: 6,
    label: "メールアドレス",
    name: "email",
    inputType: "text",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
  {
    id: 7,
    label: "お問い合わせ内容",
    name: "content",
    inputType: "textarea",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
  {
    id: 8,
    label: "案件情報や広告を含むご案内のメールを受け取る",
    name: "information",
    inputType: "checkbox",
    isRequired: false,
  },
  {
    id: 9,
    label: {
      text: "お問い合わせにおける個人情報の取扱いについてに同意する",
      link: "https://twostone-s.com/privacy/",
      linkText: "個人情報の取扱い",
    },
    name: "privacy",
    inputType: "checkbox",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
];

export const DOWNLOAD_FORM_ITEMS: FormItem[] = [
  {
    id: 1,
    label: "お名前",
    name: "name",
    inputType: "text",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
  {
    id: 2,
    label: "お名前(カナ)",
    name: "kana",
    inputType: "text",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
  {
    id: 3,
    label: "会社名",
    name: "company",
    inputType: "text",
    isRequired: false,
  },
  {
    id: 5,
    label: "電話番号",
    name: "tel",
    inputType: "text",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
  {
    id: 6,
    label: "メールアドレス",
    name: "email",
    inputType: "text",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
  {
    id: 7,
    label: "資料ダウンロードの目的",
    name: "content",
    inputType: "textarea",
    isRequired: false,
  },
  {
    id: 8,
    label: "案件情報や広告を含むご案内のメールを受け取る",
    name: "information",
    inputType: "checkbox",
    isRequired: false,
  },
  {
    id: 9,
    label: {
      text: "資料ダウンロードにおける個人情報の取扱いについてに同意する",
      link: "https://twostone-s.com/privacy/",
      linkText: "個人情報の取扱い",
    },
    name: "privacy",
    inputType: "checkbox",
    isRequired: true,
    errorMessages: {
      required: "入力必須の項目です",
    },
  },
];
