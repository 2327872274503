import Heading from "@/components/Heading";
import { COLORS } from "@/constants/theme";
import { css } from "@emotion/react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import "@/styles/react-paginate.css";
import { Link } from "react-router-dom";
import useGraphQLQuery from "@/hooks/useGraphQLQuery";
import { Helmet } from "react-helmet-async";
import { mqMax } from "@/utils/createMediaQuery";

const NewsList = () => {
  const [offset, setOffset] = useState(0); // 何番目のアイテムから表示するか
  const perPage: number = 10; // 1ページあたりに表示したいアイテムの数

  // ページネーション
  const handlePageChange = (data: { selected: number }) => {
    const pageNumber = data.selected; // クリックした部分のページ数が{selected: 2}のような形で返ってくる
    setOffset(pageNumber * perPage); // offsetを変更し、表示開始するアイテムの番号を変更
  };

  const query = `
    {
      posts {
        edges {
          node {
            postId
            title
            date
          }
        }
      }
    }
  `;
  const { data, isLoading, isError } = useGraphQLQuery(query);

  if (isError) {
    return (
      <div>Error occurred while fetching data. Please try again later.</div>
    );
  }

  return (
    <>
      <Helmet>
        <title>お知らせ | 株式会社Growth One</title>
        <meta property="og:title" content="株式会社Growth One" />
        <meta property="og:description" content="株式会社Growth One" />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Heading enText={"NEWS"} jpText={"お知らせ"} isCenter={true} />
      <div css={classes.news}>
        <ul css={classes.newsList}>
          <div>
            {!isLoading &&
              data.posts.edges
                .slice(offset, offset + perPage) // 表示したい投稿をsliceで抽出
                .map((post: any) => {
                  return (
                    <li css={classes.newsListItem} key={post.node.postId}>
                      <time>
                        {post.node.date.split("T")[0].replace(/-/g, ".")}
                      </time>
                      <Link to={`/news/${post.node.postId}`}>
                        {post.node.title}
                      </Link>
                    </li>
                  );
                })}
          </div>
          {!isLoading && (
            <ReactPaginate
              previousLabel={"<"} //前のページ番号に戻すリンクのテキスト
              nextLabel={">"} //次のページに進むボタンのテキスト
              breakLabel={"..."} // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
              pageCount={Math.ceil(data.posts.edges.length / perPage)} // 全部のページ数。端数の場合も考えて切り上げに
              marginPagesDisplayed={2} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
              pageRangeDisplayed={5} // アクティブなページを基準にして、そこからいくつページ数を表示するか
              onPageChange={handlePageChange} // クリック時のfunction
              containerClassName={"pagination"} // ページネーションであるulに着くクラス名
              pageLinkClassName={"pagination-link"} //ページネーションのリンクのクラス名
              activeClassName={"active"} // アクティブなページのliに着くクラス名
              previousClassName={"pagination-previous"} // 「<」のliに着けるクラス名
              nextClassName={"pagination-next"} // 「>」のliに着けるクラス名
              disabledClassName={"pagination-disabled"} // 使用不可の「<,>」に着くクラス名
            />
          )}
        </ul>
      </div>
    </>
  );
};

const classes = {
  news: css`
    margin-top: 96px;
    ${mqMax("small")} {
      margin-top: 64px;
    }
  `,
  newsList: css`
    margin-top: 48px;
  `,
  newsListItem: css`
    padding: 32px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f3f6f9;
    &:first-of-type {
      padding-top: 0;
    }
    time {
      color: ${COLORS.darkGray};
      font-size: 13px;
      font-weight: 500;
    }
    a {
      display: block;
      margin-left: 96px;
      transition: 0.3s;
      line-height: 1.5;
      &:hover {
        color: ${COLORS.main};
      }
    }
    ${mqMax("small")} {
      time {
        font-size: 12px;
      }
      a {
        font-size: 13px;
        margin-left: 24px;
      }
    }
  `,
  toListLink: css`
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    transition: 0.3s;
    span {
      margin-left: 12px;
      text-align: center;
      line-height: 24px;
      display: inline-block;
      font-weight: bold;
      height: 24px;
      width: 24px;
      border-radius: 100px;
      color: ${COLORS.white};
      background: ${COLORS.black};
      transition: 0.3s;
    }
    &:hover {
      color: ${COLORS.main};
      span {
        background: ${COLORS.main};
        padding-left: 2px;
      }
    }
  `,
};

export default NewsList;
