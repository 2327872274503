import { NavListItem } from "@/types/list";

export const HEADER_NAV_LIST: NavListItem[] = [
  { id: 1, name: "ホーム", link: "/", isAnker: false },
  { id: 2, name: "Growth Oneについて", link: "about", isAnker: true },
  { id: 3, name: "ソリューション", link: "solution", isAnker: true },
  { id: 4, name: "お知らせ", link: "/news", isAnker: false },
  { id: 5, name: "会社概要", link: "/company", isAnker: false },
  { id: 6, name: "資料請求", link: "/download", isAnker: false },
  { id: 7, name: "お問い合わせ", link: "/contact", isAnker: false },
];

export const FOOTER_NAV_LIST: NavListItem[] = [
  ...HEADER_NAV_LIST,
  { id: 8, name: "個人情報保護方針", link: "https://twostone-s.com/privacy/" },
  {
    id: 9,
    name: "反社会的勢力への対応に関する基本方針",
    link: "/antisocial-forces",
  },
];

export const FOOTER_EXTERNAL_NAV_LIST: NavListItem[] = [
  { id: 1, name: "TWOSTONE&Sons", link: "https://twostone-s.com/" },
  { id: 2, name: "Branding Engineer", link: "https://b-engineer.co.jp/" },
  { id: 3, name: "Midworks", link: "https://lp.mid-works.com/" },
  { id: 4, name: "tech boost", link: "https://tech-boost.jp/" },
  { id: 5, name: "Digital Arrow Partners", link: "https://darrow-p.com/" },
];
