import { COLORS } from "@/constants/theme";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";

const MainVisual = () => {
  return (
    <>
      <div css={classes.mvContainer}>
        <figure css={classes.mvMask}>
          <div css={classes.mv}></div>
        </figure>
        <div css={classes.mvInner}>
          <h2 css={classes.mvTitle}>
            テクノロジーの力で、
            <br />
            成長と自走を。
          </h2>
          <span css={classes.mvEnText}>
            Through the power of technology,
            <br />
            growth & self-driving with us.
          </span>
        </div>
      </div>
    </>
  );
};

const classes = {
  mvContainer: css`
    z-index: -1;
    width: 100%;
    height: 780px;
    ${mqMax("small")} {
      height: 560px;
    }
  `,
  mv: css`
    padding-top: 120px;
    background: url("/images/mv.webp");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    ${mqMax("small")} {
      background: url("/images/mv-sp.webp");
      background-position: center;
      // FIXME: スマホでもfixedにしたい
      background-attachment: none;
      background-size: cover;
    }
  `,
  mvMask: css`
    mask-image: url("/images/mv-mask.svg");
    mask-repeat: no-repeat;
    mask-position: 140% 65%;
    mask-size: 75% auto;
    width: 100%;
    height: 100%;
    ${mqMax("small")} {
      mask-position: 160% 65%;
      mask-size: 90% auto;
    }
  `,
  mvInner: css`
    position: relative;
    top: -480px;
    width: 1080px;
    margin: 0 auto;
    ${mqMax("small")} {
      width: 100%;
      padding: 0 16px;
      top: -320px;
    }
  `,
  mvTitle: css`
    font-size: 56px;
    font-weight: bold;
    line-height: 1.5;
    ${mqMax("small")} {
      font-size: 28px;
    }
  `,
  mvEnText: css`
    margin-top: 32px;
    display: inline-block;
    font-size: 20px;
    color: ${COLORS.darkGray};
    line-height: 1.5;
    ${mqMax("small")} {
      font-size: 14px;
    }
  `,
};

export default MainVisual;
