import { COLORS } from "@/constants/theme";
import useGraphQLQuery from "@/hooks/useGraphQLQuery";
import { mqMax } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";

const PickupNews = () => {
  const query = `
    {
      posts(first: 1, where: {orderby: {field: DATE, order: DESC}}) {
        edges {
          node {
            postId
            title
            content
            date
          }
        }
      }
    }
  `;
  const { data, isLoading, isError } = useGraphQLQuery(query);
  const post = !isLoading ? data.posts.edges[0].node : "";

  if (isError) {
    return (
      <div>Error occurred while fetching data. Please try again later.</div>
    );
  }

  return (
    <>
      {!isLoading && (
        <div css={classes.pickupNews}>
          <div css={classes.titleBox}>
            <time>{post.date.split("T")[0].replace(/-/g, ".")}</time>
            <p>{post.title}</p>
          </div>
          <div css={classes.linkBox}>
            <Link to={`/news/${post.postId}`}>
              <img src="/images/link.svg" alt="お知らせ詳細へ" />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

const classes = {
  pickupNews: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    padding: 20px 48px;
    border-radius: 200px;
    font-weight: 500;
    ${mqMax("small")} {
      padding: 12px 24px;
    }
  `,
  titleBox: css`
    display: flex;
    align-items: center;
    time {
      font-size: 14px;
      color: ${COLORS.gray};
      ${mqMax("small")} {
        font-size: 12px;
      }
    }
    p {
      font-size: 16px;
      color: ${COLORS.darkGray};
      margin: 0 auto 0 120px;
      ${mqMax("small")} {
        line-height: 1.5;
        font-size: 13px;
        margin: 4px 4px 0 0;
      }
    }
    ${mqMax("small")} {
      align-items: flex-start;
      flex-flow: column;
    }
  `,
  linkBox: css`
    border-left: 1px solid ${COLORS.gray};
    padding-left: 32px;
    img {
      width: 16px;
      height: auto;
    }
    ${mqMax("small")} {
      padding-left: 16px;
    }
  `,
};

export default PickupNews;
