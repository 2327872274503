import Heading from "@/components/Heading";
import { mqMax, mqMin } from "@/utils/createMediaQuery";
import { css } from "@emotion/react";

const About = () => {
  return (
    <>
      <div id="about" css={classes.about}>
        <img src="/images/about.webp" alt="ABOUT" />
        <div css={classes.textBox}>
          <Heading
            enText={"ABOUT"}
            jpText={`テクノロジーの力で\nビジネスを加速させる`}
          />
          <p>
            私たちGrowth Oneは、
            <br />
            テクノロジーの力を駆使して
            <br />
            お客様のビジネスを加速させるパートナです。
            <br />
            あなたのビジネスの可能性を
            <br />
            最大限に引き出すため、
            <br />
            最新のテクノロジーと
            <br />
            業界のベストプラクティスを組み合わせた
            <br />
            オーダーメイドのソリューションを提供します。
          </p>
        </div>
      </div>
    </>
  );
};

const classes = {
  about: css`
    padding: 88px 0 0;
    display: flex;
    align-items: flex-start;
    margin-top: 160px;
    width: 100%;
    height: 680px;
    background: linear-gradient(
      to right,
      rgba(243, 246, 249, 0),
      rgba(243, 246, 249, 1)
    );
    img {
      position: relative;
      width: 55%;
      height: auto;
      left: -10%;
      top: -152px;
    }
    ${mqMax("small")} {
      height: auto;
      padding: 88px 16px 0;
      flex-flow: column;
      background: linear-gradient(
        to bottom,
        rgba(243, 246, 249, 0),
        rgba(243, 246, 249, 1),
        rgba(243, 246, 249, 0)
      );
      img {
        left: 0;
        width: 100%;
      }
    }
    ${mqMin("large")} {
      margin-top: 240px;
      img {
        position: relative;
        width: 48%;
        height: auto;
        left: 0;
        top: -240px;
      }
    }
  `,
  textBox: css`
    margin-left: -40px;
    p {
      margin-top: 48px;
      line-height: 2;
      font-size: 18px;
      font-weight: bold;
    }
    ${mqMax("small")} {
      margin: -120px 0 0 0;
      p {
        font-size: 15px;
        margin-top: 32px;
      }
    }
    ${mqMin("large")} {
      margin-left: 96px;
    }
  `,
};

export default About;
