import { FC } from "react";
import { css } from "@emotion/react";
import { COLORS } from "@/constants/theme";
import { Link } from "react-router-dom";
import { mqMax } from "@/utils/createMediaQuery";

export const Thanks: FC = () => {
  return (
    <>
      <div css={classes.contact}>
        <p css={classes.thanksText}>
          フォームのご入力、
          <br css={classes.spShow} />
          誠にありがとうございます。
          <br />
          資料は
          <Link
            to={"https://growth-one.co.jp/pdf/growth-one.pdf"}
            target="_blank"
          >
            こちら
          </Link>
          からダウンロードできます。
          <br />
          <br />
          もしお困りごとや
          <br css={classes.spShow} />
          ご相談などございましたら、
          <br />
          当HP内の<Link to={"/contact"}>お問い合わせ</Link>
          ページより
          <br css={classes.spShow} />
          ご連絡ください。
        </p>
        <div css={classes.submitBtnWrap}>
          <Link to={"/"} css={classes.submitBtn}>
            トップへ戻る
          </Link>
        </div>
      </div>
    </>
  );
};

const classes = {
  spShow: css`
    display: none;
    ${mqMax("small")} {
      display: inline-block;
    }
  `,
  contact: css`
    margin-top: 96px;
    > p {
      line-height: 1.8;
    }
  `,
  form: css`
    margin-top: 48px;
  `,
  submitBtnWrap: css`
    margin-top: 80px;
    text-align: center;
  `,
  submitBtn: css`
    cursor: pointer;
    appearance: none;
    background: ${COLORS.main};
    color: ${COLORS.white};
    padding: 16px 120px;
    border-radius: 200px;
    font-weight: bold;
    border: none;
    transition: 0.3s;
    margin: 0 auto;
    display: inline-block;
    &:hover {
      opacity: 0.6;
    }
    ${mqMax("small")} {
      width: 100%;
      padding: 16px 0;
    }
  `,
  thanksText: css`
    text-align: center;
    a {
      color: ${COLORS.main};
      text-decoration: underline;
    }
  `,
};
