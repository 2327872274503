import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import nprogress from "nprogress";
import "nprogress/nprogress.css";
import "@/styles/nprogress.css";

nprogress.configure({ showSpinner: false, speed: 400, minimum: 0.25 });

const ProgressBar = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    nprogress.start();
    nprogress.done();
  }, [pathname]);

  return null;
};

export default ProgressBar;
